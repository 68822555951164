$themeColor         : #00897b;
$themeColorLight    : #e0f2f1;
$themeColorDark     : #00695c;
$titleColor         : #3c4858;
$whiteColor         : #fff;
$blackColor         : #000;
$lightGrayColor     : #d3d3d3;
$grayTextColor      : #898989;
$darkGrayColor      : #212121;
$statusDoneColor    : #75be37;
$statusPendingColor : #ea835f;
$statusProcessColor : #ecd63e;