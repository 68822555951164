/*!

=========================================================
* Material Kit PRO React - v1.8.0 based on Material Kit PRO v2.0.2 (Bootstrap 4.0.0 Final Edition)
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";

// Core Plugins
@import "core/misc";

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-tagsinput";


html {
    scroll-behavior: smooth;
}

.RatingBars {
    .react-sweet-progress-line {
        background-color: #d3d3d3 !important;
    }
}

.slick-dots {
    display: none !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockNumber-clockNumberSelected {
    background-color: $themeColor !important;
}

.MuiDialog-root {
    .MuiButton-textPrimary {
        color: $themeColor;
    }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #004d40 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #004d40 !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid #004d40 !important;
}

.StripeCheckout {
    box-shadow: none !important;
    padding   : 10px !important;
    width     : 100%;

    span {
        background-image: none !important;
        box-shadow      : none !important;
        text-shadow     : none !important;
    }
}


@keyframes fade-in-top {
    0% {
        transform: translateY(-50px);
        opacity  : 0;
    }

    100% {
        transform: translateY(0);
        opacity  : 1;
    }
}

.fade-in-top {
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-in-enter {
    opacity: 0;
}

.slide-in-active {
    opacity   : 1;
    transition: opacity 200ms;
}

.slide-in-exit {
    opacity: 1;
}

.slide-in-exit-active {
    opacity   : 0;
    transition: opacity 500ms ease-in;
}

.title--main--div {
    margin-left  : auto;
    margin-right : auto;
    width        : fit-content;
    margin-bottom: 30px;
}

.title--border {
    display   : flex;
    width     : 60%;
    height    : 2px;
    background: $themeColor ;
}

.MuiPickersDay-daySelected {
    background-color: $themeColor  !important;
}
.MuiPickersClockPointer-thumb {
 border: 14px solid $themeColor  !important;   
}